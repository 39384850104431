:root {
  /* ===== Color Platte ===== */
  --background-color: linear-gradient(312.98deg, #ebf3f5 0%, #f7f7f4 100%);
  --table-background: linear-gradient(90deg, #ce4b4e 20%, #b8191d 100%);
  --disable-color: #545c68;
  --main-color: #0088ca;
  --hover-color: #ffe1e0;
  --big-medium-size: 25px;
  --white: #fff;
  --main-text-color: #15171a;

  /* Font size */
  --small4-font-size: 14px;
  --small1-font-size: 15px;
  --normal-font-size: 18px;
  --small-font-size: 11px;
  --big-font-size: 21px;
  --big-medium-size: 25px;
  --bigger-font-size: 30px;

  /* ===== z index ===== */
  --z-fixed: 100;

  /* /===== FO=ont weight =====/ */
  --font-weight-normal: 400;
  --font-weight-medium: 600;
  --font-weight-regular: 700;
  --font-weight-bold: 900;

  /* /===== Icon  =====/ */
  --logo-height: 65px;
  --icon-height: 20px;
  --icon-width: 20px;
  --icon-gray: grayscale(100%);
}

::before,
::after {
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  scrollbar-color: gray;
  scrollbar-width: thin;
}
body {
  margin: 0;
  color: var(--main-text-color);
  font-family: "roboto", sans-serif;
  -webkit-font-smoothing: "roboto", sans-serif;
  -moz-osx-font-smoothing: grayscale;
}

button:active {
  transform: scale(0.98);
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
  transition: all 0.1s;
}

/* Table section css Start header */

.data_tablee {
  width: 100%;
  overflow: auto;
  padding-bottom: 0px;

  /* padding-bottom: 20px; */
}

.data_tablee table {
  border-collapse: collapse;
}

.data_tablee .fixHell th {
  position: sticky;
  top: 0;
  z-index: 0;
  @apply bg-mainColor text-white;
}

.fixHell tr th {
  padding: 12px;
  padding-left: 25px;
  color: #545c68;
  @apply font-semibold text-base;
}
.fixHell tr th:first-child {
  text-align: left;
}

.fixHellTd tr td {
  padding: 15px 15px 15px 25px;
  color: #122d54;
  border-bottom: 1px dotted #cac8c8;
}

.fixHellTdCost tr td {
  padding: 15px 15px 15px 25px;
  font-size: var(--small-font-size);

  border-bottom: 1px dotted #cac8c8;
}

table {
  overflow-x: auto;
  white-space: nowrap;
}

/* Table section css Start header */
