@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.gray-bg {
  background-color: #e6e6e6;
}

.red-button {
  background-color: #ce4b4e;
}

.gray-button {
  background-color: #e6e6e6;
}
.main-w {
  width: 95%;
}

.bg-red-main {
  background-color: #ffe1e0;
}

.bg-completed {
  background-color: rgba(74, 193, 146, 1);
}

.bg-attention {
  background-color: rgba(238, 214, 0, 1);
}

.bg-failed {
  background-color: rgba(184, 25, 29, 1);
}
.red-df {
  background: rgb(184, 25, 29, 1);
}
button:disabled {
  opacity: 0.6;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.background-image {
  background-image: url("../public/loginImage.svg");
  background-size: cover;
  background-position: center;
}

/* Hide the default checkbox appearance */
input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid black;
  border-radius: 4px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

/* Style for the checked state */
input[type="checkbox"]:checked {
  background-color: #ec5356;
}

/* Add a checkmark or indicator for the checked state */
input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 6px;
  width: 6px;
  height: 12px;

  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
